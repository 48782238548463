<template>
  <v-touch @pan="panHandler" @rotate="rotateHandler"  >
     
    
  <div id="full_div" style="width: 100%; z-index:2000; background-color: #283A5E;" fill-height >
     <v-container ma-0 pa-2 style="max-width: 100%;">
        <v-layout row  >
          <v-flex class="box" xs1 sm1 md1> <v-btn to="/entradas"><svgicon icon="entradas" color="white" class="entradasBtm"></svgicon></v-btn></v-flex>
          <v-flex class="box" xs5 sm5 md5 style="text-align: right;"> <img src="../assets/logo-amura.png" @click="navigate('home')" class="logoAmura"></v-flex>
          
          <v-flex class="box text-xs-right" xs4 sm4 md4> <v-btn @click="accident()" ><svgicon icon="incidencia" color="#fba83e" class="incidentBtmBtm"></svgicon></v-btn></v-flex>
          <v-flex class="box text-xs-right" > <v-btn to="/buquespuerto"><svgicon icon="desplegable" color="white" class="buquesBtm"></svgicon></v-btn></v-flex>
          <v-divider class="mx-4 divider" vertical></v-divider>
          <v-flex class="box text-xs-center logout" > <img src="../assets/bt_salir.svg" @click="logout()"></v-flex>
        </v-layout>
      </v-container>
      
  
    <l-map ref="myMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      :zoomAnimation="true"
      :trackResize="true"
      :tap="true"
      :bounceAtZoomLimits="false"
      :viewreset="ViewRestart"
      style="height: 100vh; witdh: 100%;z-index:1; cursor: pointer"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      v-on:click="getcoords($event)"
      @dblclick="incidencia($event)"
      @ready="doSomethingOnReady()"
      
   >
    <l-control-layers position="topright" collapsed: false  ></l-control-layers>
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.baseUrl"
        :attribution="tileProvider.attribution"
        format="image/png"
        layer-type="base"
        transparent
        class="titlelayer"
        
        
        
        />

      <l-wms-tile-layer
        v-for="layer in layers"
        :key="layer.name"
        :base-url="layer.baseUrl"
        :layers="layer.layers"
        :visible="layer.visible"
        :name="layer.name"
        format="image/png"
        layer-type="overlays"
        transparent
       
      />
       <l-marker 
        v-for="(itemI,index) in incidencias"  
        v-bind:key="'i'+ index"
        :lat-lng="itemI.position" 
        :visible="itemI.visible"
        :icon="itemI.icon"
        @click="onMarkerClick(itemI.content)"
        @dblclick="deleteMarker(index,itemI.id)"
      >
        <l-tooltip 
        :content="itemI.content"></l-tooltip>
        
      </l-marker>
    </l-map>
    <div class="arrow_box bocadillo " v-show="show" v-bind:style="styleObject">
      
      <div class="TituloShip">
        <h2>{{vessellPopup.ship}}</h2>
        <p>{{vessellPopup.typeship}}</p>
        <div class="botonClosePopup">
              <v-icon color="white" class="iconPopUPClose" @click="closeDialogBocadillo()">far fa-times-circle</v-icon>
        </div>
      </div>  
      <div class="fotoVessel" v-if="vessellPopup.picture !== null"><img v-bind:src="vessellPopup.picture"  width="213" @error="imageUrlAlt"/></div>
      <div class="textoBocadillo">
        <p v-if="vessellPopup.imo !== null"><strong>IMO: </strong>{{vessellPopup.imo}}</p>
        <p v-else><strong>MMSI: </strong>{{vessellPopup.mmsi}}</p>
        <p ><strong>Port: </strong>{{vessellPopup.distancepobr}}</p>
        <p ><strong>ETA: </strong>{{vessellPopup.distanceport}}</p>
        <p ><strong>Size: </strong>{{vessellPopup.length}} x {{vessellPopup.sleeve}} m</p>
        <p ><strong>Status: </strong>{{vessellPopup.state}}</p>
        <p ><strong>SOG/HDG: </strong>{{vessellPopup.sog}} Kn / {{vessellPopup.hdg}}º</p>
        <p ><strong>Dwt: </strong>{{vessellPopup.dwt}}</p>
        <p><strong>GT: </strong>{{vessellPopup.gt}}</p>
        <p><strong>Draught: </strong>{{vessellPopup.draft}}</p>
        
      </div>
      
    </div>
    <div class="arrow_boxP bocadilloP " v-show="showP" v-bind:style="styleObject">
      <div class="TituloShipP">
        <h2>{{vessellPopup.ship}}</h2>
        <p>{{vessellPopup.typeship}}</p>
        <div class="botonClosePopup">
              <v-icon color="dark" class="iconPopUPClose" @click="closeDialogBocadillo()">far fa-times-circle</v-icon>
        </div>
      </div>  
      <div class="fotoVessel" v-if="vessellPopup.picture !== null"><img v-bind:src="vessellPopup.picture"  width="213" @error="imageUrlAlt" /></div>
      <div class="textoBocadillo">
        <p v-if="vessellPopup.imo !== null"><strong>IMO: </strong>{{vessellPopup.imo}}</p>
        <p v-else><strong>MMSI: </strong>{{vessellPopup.mmsi}}</p>
        <p><strong>Port: </strong>{{vessellPopup.port}}</p>
        <p><strong>ETD:</strong>{{vessellPopup.etd}}</p>
        <p><strong>Size: </strong>{{vessellPopup.length}} x {{vessellPopup.sleeve}} m</p>
        <p><strong>Status: </strong>{{vessellPopup.state}}</p>
        <p><strong>SOG/HDG: </strong>{{vessellPopup.sog}} Kn / {{vessellPopup.hdg}}º</p>
        <p><strong>Dwt: </strong>{{vessellPopup.dwt}}</p>
        <p><strong>GT: </strong>{{vessellPopup.gt}}</p>
        <p><strong>Draught: </strong>{{vessellPopup.draft}}</p>
        
      </div>
    </div>
    <div class="arrow_boxPU bocadilloPU " v-show="showPU" v-bind:style="styleObject">
      <div class="TituloShip">
        <h2>{{vessellPopup.ship}}</h2>
        <p>{{vessellPopup.typeship}}</p>
        <div class="botonClosePopup">
              <v-icon color="white" class="iconPopUPClose" @click="closeDialogBocadillo()">far fa-times-circle</v-icon>
        </div>
      </div>  
      <div class="fotoVessel" v-if="vessellPopup.picture !== null"><img v-bind:src="vessellPopup.picture"  width="213" @error="imageUrlAlt" /></div>
      <div class="textoBocadillo">
        <p v-if="vessellPopup.imo !== null"><strong>IMO: </strong>{{vessellPopup.imo}}</p>
        <p v-else><strong>MMSI: </strong>{{vessellPopup.mmsi}}</p>
        <p><strong>ETA: </strong>{{vessellPopup.distanceport}}</p>
        <p><strong>Port: </strong>{{vessellPopup.distancepobr}}</p>
        <p><strong>Size: </strong>{{vessellPopup.length}} x {{vessellPopup.sleeve}} m</p>
        <p><strong>Status: </strong>{{vessellPopup.state}}</p>
        <p v-if="vessellPopup.hdg === 511"><strong>SOG/HDG: </strong>{{vessellPopup.sog}} Kn / 0º</p>
        <p v-else><strong >SOG/HDG: </strong>{{vessellPopup.sog}} Kn / 0º</p>
        <p><strong>Draught: </strong>{{vessellPopup.draft}}</p>
        <p><strong>GT: </strong>{{vessellPopup.gt}}</p>
        <p><strong>Dwt: </strong>{{vessellPopup.dwt}}</p> 
        <p><strong>Noray's: </strong>{{vessellPopup.noray1}} - {{vessellPopup.noray2}}</p>
        
      </div>
    </div>
  <v-dialog v-model="dialog" persistent max-width="400" @click="closeDialog">
      <v-card class="dialogBox">
              <div class="botonClose">
              <v-btn text @click="closeDialog()"><v-icon color="white">far fa-times-circle</v-icon></v-btn>
              </div>
        
          <v-card-title class="headline textcolor txtCard">Seleccione una Incidencia</v-card-title>
       
        <v-card-actions >
         
              <v-spacer></v-spacer>
              <v-btn @click="reportIncident('barco')"><img src="@/assets/pin/barco.png" class="pin" /></v-btn>
              <v-btn @click="reportIncident('buzo')"><img src="@/assets/pin/buzo.png" class="pin" /></v-btn>
              <v-btn @click="reportIncident('barrera')"><img src="@/assets/pin/barrera.png" class="pin" /></v-btn>
              <v-btn @click="reportIncident('grua')"><img src="@/assets/pin/grua.png" class="pin" /></v-btn>
              
           
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
  
  </v-touch>
  
</template>
<script>
import axios from 'axios';
import L from 'leaflet';
import { CRS, latLng, icon, latLngBounds, map } from "leaflet";
import { LMap, LTileLayer, LMarker, LControlLayers,
 LPopup, LTooltip, LWMSTileLayer, LGeoJson } from "vue2-leaflet";
import '../compiled-icons/logoamura';
import WidgetsMenu from '../components/WidgtesMenu.vue'; 
import LogbookWidget from './LogbookWidget.vue';
import authService from "@/services/authService";

export default {
  name: "Example3",
  
  components: {
    LMap,
    'l-wms-tile-layer': LWMSTileLayer,
    LControlLayers,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LGeoJson,
    WidgetsMenu,
    LogbookWidget
  },
  props: ['currentToken'],
  data() {
    let lat;  
    let lon;
    let pointsX;
    let pointsY;
    let points;
    let info;
    let icono;
    let imgflag;
    let pic;
    let horasF;
    let group1;
    let radar;
    let indice;

    let  iconBarco = new L.icon({
          iconUrl: require('../assets/pin/pin-barco.png'),
          iconSize:     [60, 60], 
        });
        
    let  iconGrua = new L.icon({
          iconUrl: require('../assets/pin/pin-grua.png'),
          iconSize:     [60, 60], 
        });  
     let  iconBarrera = new L.icon({
          iconUrl: require('../assets/pin/pin-barrera.png'),
          iconSize:     [60, 60], 
        }); 
    let  iconBuzo = new L.icon({
          iconUrl: require('../assets/pin/pin-buzo.png'),
          iconSize:     [60, 60], 
        });
    return {
      widgets: true,
      loading: false,
      visible: false,
      geojson:null,
      windowSizeX: 0,
      windowSizeY: "1300px",
      zoom: parseInt(process.env.VUE_APP_ZOOM),      
      center: latLng(parseFloat(process.env.VUE_APP_LAT), parseFloat(process.env.VUE_APP_LON)),
      url: process.env.VUE_APP_URLMAPBOXFONDO,
      bounds: latLngBounds([
        [parseFloat(process.env.VUE_APP_BOUNDLATSUP), parseFloat(process.env.VUE_APP_BOUNDLONSUP)],
        [parseFloat(process.env.VUE_APP_BOUNDLATINF), parseFloat(process.env.VUE_APP_BOUNDLONINF)]
      ]),
      maxBounds: latLngBounds([
       [parseFloat(process.env.VUE_APP_BOUNDLATSUP), parseFloat(process.env.VUE_APP_BOUNDLONSUP)],
       [parseFloat(process.env.VUE_APP_BOUNDLATINF), parseFloat(process.env.VUE_APP_BOUNDLONINF)]
      ]),
      showNoray: false,
      iconBarco,
      iconBarrera,
      iconBuzo,
      iconGrua,
      dialog: false,
      componentKey: 0, 
      layers : [
        {
          name: "Noray",
          visible: false,
          minZoom:15,
          layers: "norays",
          baseUrl: process.env.VUE_APP_GEOSERVER,
          zIndex:5,
          
        },
        {
          name: "Batimetria 1m",
          visible: false,
          layers: "BatimetriaDGC",
          baseUrl: process.env.VUE_APP_REDMIC
          
        },
        {
          name: "Batimetria de Canarias 50m",
          visible: false,
          layers: "BatimetriaIEO",
          baseUrl: process.env.VUE_APP_REDMIC
        },
        {
          name: "Warnings",
          visible: false,
          layers: "incidents",
          baseUrl:  process.env.VUE_APP_GEOSERVER
        },
        
        
      ],
      tileProviders: [
        {
          name: 'Diurno',
          visible: true,
          attribution:process.env.VUE_APP_ATRIBUTTION,
          baseUrl: process.env.VUE_APP_URLMAPBOXDIURNO,
          zIndex:1, 
        },
        {
          name: 'Nocturno',
          visible: false,
          baseUrl:  process.env.VUE_APP_URLMAPBOXNOCTURNO,
          attribution: process.env.VUE_APP_ATRIBUTTION,
          zIndex:1,
        }
      ],
      attribution:process.env.VUE_APP_ATRIBUTTION,
      currentZoom: parseInt(process.env.VUE_APP_ZOOM),
      currentCenter: latLng(parseFloat(process.env.VUE_APP_LAT), parseFloat(process.env.VUE_APP_LON)),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 1,
        maxZoom: parseInt(process.env.VUE_APP_ZOOMMAX),
        minZoom: parseInt(process.env.VUE_APP_ZOOMMIN),
        draggable: false,
        
      },
      
     
     markers: [],
     vesselJson: [],
     stopMarkers:[],
     incidencias:[],
     points:[],
     info:[],
     geojson: null,
     markersNorays: [],
     flags:[],
     newNorays:[],
     capa:[],
     varBatimetria:false,
     incident:[],
     show: false,
     showE: false,
     showS: false,
     showP: false,
     showPU: false,
     showM: false,
     showN: false,
     layerCarga:null,
     layerActual:null,
     layerCargaNombre: L.layerGroup(),
     layerActualNombre: L.layerGroup(),
     layerCargaStatic:null,
     layerActualStatic:null,
     layerNorays:null,
     layerActualNombreGruasStatic:null,
     layerSecurityAreasCargaStatic:null,
     layerSecurityAreasActualStatic:null,
     layerCargaNombreStatic: L.layerGroup(),
     layerActualNombreStatic: L.layerGroup(),
     layerCargaDynamic:null,
     layerActualDynamic:null,
    //  layerSecurityAreasCargaDynamic:null,
    //  layerSecurityAreasActualDynamic:null,
     layerCargaNombreDynamic: L.layerGroup(),
     layerActualNombreDynamic: L.layerGroup(),
     redrawint : Math.floor(Math.random() * 200000) + 1,
     styleObject: {
        top: '364px',
        left: '1583px'
      },
     vessellPopup:[],
     foto:"../assets/no-pic.jpg",

     
    };
  },

  async created() {
    this.estadoInicial();
    this.crearLocalStorage();
    
    
  },

  mounted(){
    let Pusher = document.createElement('script');    
    Pusher.setAttribute('src',"//js.pusher.com/7.0/pusher.min.js");
    document.head.appendChild(Pusher);
    let ckeditor2 = document.createElement('script');    
    ckeditor2.setAttribute('src',"//cdn.jsdelivr.net/npm/vue/dist/vue.min.js");
    document.head.appendChild(ckeditor2);
   
    
  },

  methods: {
    logout() {
      authService.logout();
      this.$router.replace("/login");
    },
    ViewRestart(){
      // console.log("redibujar" )
    },
    ocultar() {
        this.widgets = !this.widgets;
    },
    doSomethingOnReady() {
        this.map = this.$refs.myMap.mapObject;

        this.wmsvessellSecurityAreasStatic();
        this.map.addLayer(this.layerSecurityAreasActualStatic);

        this.wmsvessellNorays();
        this.map.addLayer(this.layerNorays);

        this.wmsvessellPoligonActualStatic();
        this.map.addLayer(this.layerActualStatic);

        this.wmsvessellNombreGruasActualStatic();
        this.map.addLayer(this.layerActualNombreGruasStatic);

        this.wmsvessellPoligonActualDynamic();
        this.map.addLayer(this.layerActualDynamic);

        this.wmsvessellNombreActualDynamic();
        this.map.addLayer(this.layerActualNombreDynamic);

        this.startInterval();
        this.startMessage();
    },
    ControlLayerAdd(){
        var layerControl = {
          "berth" : this.layers.name.Norays
        }
        L.control.layers(layerControl).addTo(this.map);
    },
    imageUrlAlt(event) {
        event.target.src = "../img/no-pic.jpg";
    },
    getRedrawInteger(){
        return this.redrawint;  
    },
    incrementRedrawInteger(){
        this.redrawint += 1;  
    },
    crearLocalStorage(){
        let datosDB = null;
        axios.get(process.env.VUE_APP_API.trim() + "/api/incidents", 
        { 'headers': { 'Authorization': 'Bearer ' + JSON.parse(authService.getUserLogged()).token } })
          .then((result) => {
          if(result.data) {
            result.data.data.forEach((value, key) => {
              let cords = value.coord.split(",",2); 
              if(value.type === "barco"){
                this.incidencias.push({
                  position: [cords[0],cords[1]],
                  visible:true,
                  content: "Barco fonde:" + value.coord,
                  id: value.id,
                  icon: this.iconBarco
                })
              }
              if(value.type === "buzo"){
                this.incidencias.push({
                  position: [cords[0],cords[1]],
                  visible:true,
                  content: "Buzos:" + value.coord,
                  id: value.id,
                  icon: this.iconBuzo,
                })
              }
              if(value.type === "grua"){
                this.incidencias.push({
                  position: [cords[0],cords[1]],
                  visible:false,
                  content: "Grua:" + value.coord,
                  id: value.id,
                  icon: this.iconGrua,
                })
              }
              if(value.type === "barrera"){
                this.incidencias.push({
                  position: [cords[0],cords[1]],
                  visible:true,
                  content: "Barrera:" + value.coord,
                  id: value.id,
                  icon: this.iconBarrera
                })
              }
            });
          }  
        })
        .catch(function (error) {
          console.log(error);
        }); 
      
        if(datosDB === null){
          this.incidencias=[];

        }else{
          this.incidencias = datosDB;
          this.incidencias.forEach(function (value) {
            value.icon = new L.icon(value.icon.options);
          });
        } 
    },
    // static layers
    wmsvessellPoligonActualStatic(){
      let randint = this.getRedrawInteger();
      this.layerActualStatic = L.tileLayer.wms(process.env.VUE_APP_GEOSERVER+randint,
          {
              
              REQUEST: 'GetMap',
              transparent: true,
              layers: 'vesselStatics',
              format: 'image/png',
              version: '1.1.1',
              visible: true,
              zIndex:5,
              
          });
    },
    wmsvessellSecurityAreasStatic(){
      let randint = this.getRedrawInteger();
      this.layerSecurityAreasActualStatic = L.tileLayer.wms(process.env.VUE_APP_GEOSERVER+randint,
          {
              
              REQUEST: 'GetMap',
              transparent: true,
              layers: 'securityAreasStatics',
              format: 'image/png',
              version: '1.1.1',
              visible: true,
              zIndex:5,
              
          });
    },
    wmsvessellNorays(){
      let randint = this.getRedrawInteger();
      this.layerNorays = L.tileLayer.wms(process.env.VUE_APP_GEOSERVER+randint,
          {
              
              REQUEST: 'GetMap',
              transparent: true,
              layers: 'norays',
              format: 'image/png',
              version: '1.1.1',
              visible: false,
              minZoom:17,
              zIndex:7,
              
          });
    },
    wmsvessellNombreGruasActualStatic(){
      let randint = this.getRedrawInteger();
      this.layerActualNombreGruasStatic = L.tileLayer.wms(process.env.VUE_APP_GEOSERVER+randint,
          {
              
              REQUEST: 'GetMap',
              transparent: true,
              layers: 'CranePoligonEslvc',
              format: 'image/png',
              version: '1.1.1',
              visible: true,
              zIndex:10
              
              }); 
    },
    // dynamic layers
    wmsvessellPoligonActualDynamic(){
        let randint = this.getRedrawInteger();
        this.layerActualDynamic = L.tileLayer.wms(process.env.VUE_APP_GEOSERVER+randint,
            {
                
                REQUEST: 'GetMap',
                transparent: true,
                layers: 'vesselDynamics',
                format: 'image/png',
                version: '1.1.1',
                visible: true,
                zIndex:5,
                
            });
             //console.log("capa vieja de buques"); 
    },
    wmsvessellNombreActualDynamic(){
        let randint = this.getRedrawInteger();
        this.layerActualNombreDynamic = L.tileLayer.wms(process.env.VUE_APP_GEOSERVER+randint,
            {
                
                REQUEST: 'GetMap',
                transparent: true,
                layers: 'vessellName',
                format: 'image/png',
                version: '1.1.1',
                visible: true,
                zIndex:800
                
               }); 
             //console.log("capa vieja nombre de buques");
    },
    swapImgsStatic() {
        this.incrementRedrawInteger();

        this.map.removeLayer(this.layerSecurityAreasActualStatic);
        this.wmsvessellSecurityAreasStatic();
        this.map.addLayer(this.layerSecurityAreasActualStatic);

        this.map.removeLayer(this.layerActualStatic);
        this.wmsvessellPoligonActualStatic();
        this.map.addLayer(this.layerActualStatic);

        this.map.removeLayer(this.layerActualNombreGruasStatic);
        this.wmsvessellNombreGruasActualStatic();
        this.map.addLayer(this.layerActualNombreGruasStatic);
    },
    swapImgsDynamic() {
        this.incrementRedrawInteger();

        this.map.removeLayer(this.layerActualDynamic);
        this.wmsvessellPoligonActualDynamic();
        this.map.addLayer(this.layerActualDynamic);

        this.map.removeLayer(this.layerActualNombreDynamic);
        this.wmsvessellNombreActualDynamic();
        this.map.addLayer(this.layerActualNombreDynamic);
    },
    removeCurrentStatic() {
        
        this.map.removeLayer(this.layerActualStatic);
        // this.map.removeLayer(this.layerActualNombreStatic);
        this.map.removeLayer(this.layerSecurityAreasActualStatic);

        //console.log("remover ambas capa");
        var swap = this.layerCargaStatic;
        // var swap2 = this.layerCargaNombreStatic;
        var swap3 = this.layerSecurityAreasCargaStatic;

        this.layerActualStatic = swap;
        // this.layerActualNombreStatic = swap2;
        this.layerSecurityAreasActualStatic = swap3;
        //console.log("cambio de capa actual");
    },
    removeCurrentDynamic() {
        
        this.map.removeLayer(this.layerActualDynamic);
        this.map.removeLayer(this.layerActualNombreDynamic);
        // this.map.removeLayer(this.layerSecurityAreasActualDynamic);

        //console.log("remover ambas capa");
        var swap = this.layerCargaDynamic;
        var swap2 = this.layerCargaNombreDynamic;
        // var swap3 = this.layerSecurityAreasCargaDynamic;
        this.layerActualDynamic = swap;
        this.layerActualNombreDynamic = swap2;
        // this.layerSecurityAreasActualDynamic = swap3;
        //console.log("cambio de capa actual");
    },
    zoomUpdate(zoom) {
        if(this.show === true){
          this.showHide();
        }
        this.currentZoom = zoom;
          if(this.currentZoom >= 17){
            this.layers[0].visible = true;
          }else{
            this.layers[0].visible = false;
          }
    },
    centerUpdate(center) {
        this.currentCenter = center;
    },
    getcoords(e){
      
      if(this.show === true){
        this.show = false;
      }
      if(this.showPU === true){
        this.showPU = false;  
      }
      if(this.showP === true){
        this.showP = false;  
      }
      var layerHere = this.map.hasLayer(this.group1);
      if(layerHere === true){
        this.map.removeLayer(this.group1);
      }
      
      this.lat = e.latlng.lat;
      this.lon = e.latlng.lng;
      
      axios.post(process.env.VUE_APP_API.trim() + '/api/tramas/glovo', {
        latitude: this.lat,
        length: this.lon 
      }, 
      { 'headers': { 'Authorization': 'Bearer ' + JSON.parse(authService.getUserLogged()).token } })
      .then((response =>{
        if(response.data.message !== "No data"){
          if(response.data.data.name === null){
            response.data.data.name = "Desconocido";  
          }

           if(response.data.data.type === null){
            response.data.data.type = "Desconocido";  
          }
          
          if(response.data.data.eta === ""){
            response.data.data.eta = null;  
          }
          if(response.data.data.etd === ""){
            response.data.data.etd = null;  
          }

          if (response.data.data.imo){
            if(response.data.data.imo.indexOf("MMSI") === 0){
              response.data.data.imo = null; 
            }
          }
          
          if(response.data.data.gt === "0"){
            response.data.data.gt = null; 
          }

           if(response.data.data.gt === ""){
            response.data.data.gt = null; 
          }
          if(response.data.data.maximumdraught === "0"){
            response.data.data.maximumdraught = null; 
          }

          
          this.vessellPopup = {
          'callsign': response.data.data.callsign,
          'port': response.data.data.port,
          'etd': response.data.data.etd,
          'eta': response.data.data.eta,
          'draft': response.data.data.maximumdraught,
          'gt' : response.data.data.gt,
          'dwt' : response.data.data.dwt,
          'distanceport': response.data.data.distanceport,
          'distancepobr': response.data.data.distancebr,
          'hdg': response.data.data.hdg,
          'imo': response.data.data.imo,
          'ship': response.data.data.name,
          'sog': response.data.data.sog,
          'state': response.data.data.state,
          'typeship': response.data.data.type,
          'utc': response.data.data.utc,
          'sleeve':response.data.data.beam ,
          'length':response.data.data.length,
          'picture': response.data.data.photo,
          'noray1': response.data.data.norayinicio,
          'noray2': response.data.data.norayfinal,
          'mmsi' : response.data.data.mmsi,
          'recive' : response.data.data.time
          };

         
          let centerX = full_div.offsetLeft + full_div.offsetWidth / 2;
          let centerY = full_div.offsetTop + full_div.offsetHeight / 2;
          
          if(response.data.data.state === "T"){
            this.showHide();
            let radius = 100;
            this.group1 = L.featureGroup();
            var circle = L.circle([this.lat,this.lon], radius).addTo(this.group1);
            this.map.addLayer(this.group1);
          }
          if(response.data.data.state === "S"){
            this.showHideS();
          }
          if(response.data.data.state === "E"){
            this.showHideE();
          }
          if(response.data.data.state === "M"){
            this.showHideM();
          }
          if(response.data.data.state === "BERTH" || response.data.data.state === "BERTH_1" || response.data.data.state === "ANCHORED" ){
            this.showHidePU();
            let radius = 100;
            this.group1 = L.featureGroup();
            var circle = L.circle([this.lat,this.lon], radius).addTo(this.group1);
            this.map.addLayer(this.group1);
          }
          if(response.data.data.state === "PILOTONBOARD"){
            this.showHideP();
            let radius = 100;
            this.group1 = L.featureGroup();
            var circle = L.circle([this.lat,this.lon], radius).addTo(this.group1);
            this.map.addLayer(this.group1);
          }
          
          
        }
        
      })) 
      .catch(function (error) {
        // console.log(error);
      });
      
      this.styleObject = {
        left: 130 + 'px',
        top: 130 + 'px'
      }
      
    },
    showHide: function(){
      this.show = true;
    },
    showHideE: function(){
      this.showE = true;
    },
    showHideS: function(){
      this.showS = true;
    },
    showHideM: function(){
      this.showM = true;
    },
    showHideP: function(){
      this.showP = true;
    },
    showHidePU: function(){
      this.showPU = true;
    },
    showHideN: function(){
      this.showN = !this.showN;
    },
    incidencia(e){
      this.lat = e.latlng.lat;
      this.lon = e.latlng.lng;
      this.dialog = true;
    },
    panHandler (direction) {
            //console.log(direction)  // May be left / right / top / bottom
    },
    rotateHandler (direction) {
            //console.log(direction)  // May be left / right / top / bottom
    },
    renderStatic() {
      this.swapImgsStatic();
    },
    renderDynamic() {
      this.swapImgsDynamic();
    },
    renderStatic() {
      this.swapImgsStatic();
    },
    renderDynamic() {
      this.swapImgsDynamic();
    },
    closeDialog() {
      this.dialog =false;
      
    },
    closeDialogBocadillo(){
      this.show = false;
      this.showS = false;
      this.showE = false;
      this.showM = false;
      this.showPU = false;
      this.showP = false;
      this.map.removeLayer(this.group1);
    },
    reportIncident(type){
      this.indice = 0;
      let incident = {
        latitude: this.lat,
         longitude: this.lon,
        type:type,
        indice:this.indice,
      };
      
      axios.post(process.env.VUE_APP_API.trim() + "/api/incidents",incident, 
      { 'headers': { 'Authorization': 'Bearer ' + JSON.parse(authService.getUserLogged()).token } })
      .then((result) => {
        this.dialog = false;
        if(incident.type === "barco"){
        this.incidencias.push({
          id: result.data.data.id,
          position: [this.lat,this.lon],
          visible:true,
          content: "Barco fonde:" + this.lat + "," + this.lon,
          icon: this.iconBarco
        })
      }
      if(incident.type === "buzo"){
        this.incidencias.push({
          id: result.data.data.id,
          position: [this.lat,this.lon],
          visible:true,
          content: "Buzos:" + this.lat + "," + this.lon,
          icon: this.iconBuzo,
        })
      }
      if(incident.type === "grua"){
        this.incidencias.push({
          id: result.data.data.id,
          position: [this.lat,this.lon],
          visible:true,
          content: "Grua:" + this.lat + "," + this.lon,
          icon: this.iconGrua,
        })
      }
      if(incident.type === "barrera"){
        this.incidencias.push({
        id: result.data.data.id,  
        position: [this.lat,this.lon],
        visible:true,
        content: "Barrera:" + this.lat + "," + this.lon,
        icon: this.iconBarrera
      })
      }
      localStorage.setItem('incidencias_palmas',JSON.stringify(this.incidencias));
      this.layers[4].visible = true; 
      })
      .catch(function (error) {
        // console.log(error);
      }); 
      

    },
    onMarkerClick(infoContent){},
    deleteMarker(index,id){
        this.incidencias.splice(index,1);
        
        axios.delete(process.env.VUE_APP_API.trim() + "/api/incidents/"+id, 
        { 'headers': { 'Authorization': 'Bearer ' + JSON.parse(authService.getUserLogged()).token } })
       .then((result) => {
         localStorage.setItem('incidencias_palmas',JSON.stringify(result));
         //this.layers[4].visible = true; 
       })
      .catch(function (error) {
        // console.log(error);
      }); 
      
    },
    startInterval: function () {    

      setInterval(() => {
        this.renderStatic();
      }, 60000);

      setInterval(() => {
        this.renderDynamic();
      }, 10000);
    },
    startMessage(){    
      setTimeout(() => {
        this.Message();
      }, 3500);
    },
    async estadoInicial(){
      axios.get(process.env.VUE_APP_API_PORT_ESTATE.trim() + process.env.VUE_APP_PUERTO.trim() +"|" + process.env.VUE_APP_USER.trim() + "|" + process.env.VUE_APP_PASS.trim(), 
      { 'headers': { 'Authorization': 'Bearer ' + JSON.parse(authService.getUserLogged()).token } })
      .then((result) => {
        // console.log("logré estado portuario ");
        //console.log("estado portuario " + result);
        //console.log("Estado Portuario: " + process.env.VUE_APP_PUERTO.trim());
      }) 
      .catch(function (error) {
        //console.log(error);
      }); 

    }, 
    batimetria(){
      if(this.varBatimetria === false){
        this.layers[2].visible = true;
        this.layers[3].visible = true;
        this.varBatimetria = true;  
      }else{
        this.layers[2].visible = false;
        this.layers[3].visible = false; 
        this.varBatimetria = false;  
      }
    },
    salidas(){
      // console.log("Configurar Amura");
    },
    muelles(){
       if(this.layers[8].visible === true){
        this.layers[8].visible = false;  
        this.layers[9].visible = true;    
      }else{
        this.layers[8].visible = true;  
        this.layers[9].visible = false;    
      }  
    },
    entradas(){
      // console.log("Configurar Amura");
    },
    accident(){
      if(this.layers[12].visible === true){
        this.layers[12].visible = false;  
      }else{
        this.layers[12].visible = true;    
      }
    },
    POB(){
      if(this.layers[3].visible === true){
        this.layers[3].visible = false; 
        
      }else{
        this.layers[3].visible = true;  
        
      }
    },
    move(){
      if(this.layers[10].visible === true){
        this.layers[10].visible = false; 
        this.layers[9].visible = true; 
      }else{
        this.layers[10].visible = true;  
         this.layers[9].visible = false;   
      }
    },
    PopUpHttp(data){
      if(this.show === true){
            this.show = false;
          }
          if(this.showPU === true){
            this.showPU = false;  
          }
          if(this.showP === true){
            this.showP = false;  
          }
          var layerHere = this.map.hasLayer(this.group1);
          if(layerHere === true){
            this.map.removeLayer(this.group1);
          }
          this.lat = parseFloat(data.latitude);
          this.lon = parseFloat(data.longuitude);
          if(data.name === null){
            data.name = "Desconocido";  
          }
          if(data.type === null){
            data.type = "Desconocido";  
          }
           if(data.subtype === null){
            data.subtype = "Desconocido";  
          }
          this.vessellPopup = {
            'name':  data.name,
            'type':  data.type,
            'subtype':  data.subtype,
            'callsign':data.callsign,
            'port':data.port,
            'etd':data.etd,
            'eta':data.eta,
            'draft':data.maximumdraught,
            'gt' :data.gt,
            'dwt' :data.dwt,
            'distanceport':data.distanceport,
            'distancepobr':data.distancebr,
            'hdg':data.hdg,
            'imo':data.imo,
            'ship':data.name,
            'sog':data.sog,
            'state':data.state,
            'typeship':data.type,
            'utc':data.utc,
            'sleeve':data.beam ,
            'length':data.length,
            'picture':data.photo,
            'noray1':data.norayinicio,
            'noray2':data.norayfinal,
            'mmsi' :data.mmsi,
            'recive' :data.time
          };
          let centerX = full_div.offsetLeft + full_div.offsetWidth / 2;
          let centerY = full_div.offsetTop + full_div.offsetHeight / 2;
          

          if(data.state === "T"){
            this.showHide();
            let radius = 100;
            this.group1 = L.featureGroup();
            var circle = L.circle([this.lat,this.lon], radius).addTo(this.group1);
            this.map.addLayer(this.group1);
          }
          if(data.state === "S"){
            this.showHideS();
          }
          if(data.state === "E"){
            this.showHideE();
          }
          if(data.state === "M"){
            this.showHideM();
          }
          if(data.state === "BERTH" || data.state === "BERTH_1" || response.data.data.state === "ANCHORED" ){
            this.showHidePU();
            let radius = 100;
            this.group1 = L.featureGroup();
            var circle = L.circle([this.lat,this.lon], radius).addTo(this.group1);
            this.map.addLayer(this.group1);
          }
          if(data.state === "PILOTONBOARD"){
            this.showHideP();
            let radius = 100;
            this.group1 = L.featureGroup();
            var circle = L.circle([this.lat,this.lon], radius).addTo(this.group1);
            this.map.addLayer(this.group1);
          }
          this.styleObject = {
            left: 130 + 'px',
            top: 130 + 'px'
          }
    },
    Message() {
      Pusher.logToConsole = false;
      var pusher = new Pusher('622ecc919eafd8b21762', {
        cluster: 'eu'
      });
      let evento = process.env.VUE_APP_PUERTO.trim();
      var channel = pusher.subscribe('my-channel');
        channel.bind(evento, (data) => {
          this.PopUpHttp(data);
        })
    }
  },
}
</script>
<style scoped>
.iconPopClose{
  background-color: black;
  border-radius: 50px;
}
h2{
  line-height: 1 !important;
  font-size:12pt !important;
}
.arrow_box {
	position: relative;
	background: green;
	border: 1
  px solid green;
}

.arrow_boxS {
	position: relative;
	background: #ABB7B7;
	border: 1
  px solid #ABB7B7;
}

.arrow_boxE {
	position: relative;
	background: #B877DB;
	border: 1
  px solid #B877DB;
}

.arrow_boxM {
	position: relative;
	background: #f5fa5e;
	border: 1
  px solid #f5fa5e;
}

.arrow_boxP {
	position: relative;
	background: #F5FA5E;
	border: 1
  px solid #F5FA5E;
}

.bocadillo{
   /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: green;
    position: absolute;
    top: var(--pointsX) ;
    left: var(--pointsY) ;
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
    width: 217px;
}

.bocadilloS{
   /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #abb7b7;
    position: absolute;
    top: var(--pointsX) ;
    left: var(--pointsY) ;
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
}

.bocadilloE{
   /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #293a5e;
    position: absolute;
    top: var(--pointsX) ;
    left: var(--pointsY) ;
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
}

.bocadilloM{
   /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #abed00;
    position: absolute;
    top: var(--pointsX) ;
    left: var(--pointsY) ;
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
}
.bocadilloP{
   /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #F5FA5E;
    position: absolute;
    top: var(--pointsX) ;
    left: var(--pointsY) ;
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
    width: 217px;
}

.bocadilloPU{
   /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #FF4B66;
    position: absolute;
    top: var(--pointsX) ;
    left: var(--pointsY) ;
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
    width: 217px;
}


.TituloShip{
  color: white;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size:9pt;
}

.TituloShipP{
   color: black;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size:9pt;  
}

.bocadillo .textoBocadillo, .bocadilloS .textoBocadillo, .bocadilloE .textoBocadillo, .bocadilloM .textoBocadillo, .bocadilloP .textoBocadillo, .bocadilloPU .textoBocadillo {
 background-color: #F0FED3;
    color: black;
    line-height: 0.5;
    /*margin-top: 7px;*/
    font-size: 11pt;
    padding: 11px;
    padding-bottom: 1px;
    border-radius: 10px;
    padding-left: 20px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.dialogBox{
  width: 100%;
  height: 219px !important;
}
.botonClose{
  width:"100%";
  text-align: right;
}

.logoAmura{
  max-width:150px;
  
 
}



.botonClosePopup{
  margin-top: -44px;
  margin-left: 176px;
  clear: both;
  position: absolute;
}

.txtCard{
  width:"100%";
  text-align: center !important;
}
.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
    background: white !important;
    color: black !important;
    

}
.v-card {
   width: 100%;
   height: 200px; 
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
button{
  width:20%;
  height: 54px;
}
.pin{
  width:75%;
  
}

.popupFlexCont{
  width:400px;
 	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
  
}

.popupFlexFoto{
  width:100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
}

.popupFooter{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
}

.itemBandera{
  width:13% !important;
  border: 1px solid aqua;
  padding: 3px;
  background-color: aqua;
  border-radius: 9px;
}
.fotoBandera{
  width:100%;
}

.itemNombreBarco{
  color: blue;
  text-transform: uppercase;
  font-weight: bolder;
}

.typeship{
  color:black;
  font-weight: 100;
  text-transform: none;
}


.iconoRotacion{
  transform: rotateY(60deg);
}

.tile, tile:hover {
  color:"primary";
}
.tile:active{
  color: "white";
}



.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
    background-color: #293a5e !important;
    box-shadow: none !important;
}

.theme--light.v-btn {
    color: #293a5e !important;
}

.v-toolbar__content{
  padding:0px !important;
}

.borde{
  border:5px white solid;
  border-radius:25px;
}

.fondosal{
  border-color:#abb7b7;
  background-color:#abb7b7;
}

.fondoent{
  border-color:#b877db;
  background-color:#b877db;
}

.fondobc{
   border-color:#abed00;
  background-color:#abed00;  
}
.fondoPOB{
   border-color:#f5fa5e;
  background-color:#f5fa5e;  
}

.fondobar{
  border-color:#ff4b66;
  background-color:#ff4b66;  
}

.fondobat{
  border-color:#84cbff;
  background-color:#84cbff;  
}

.fondoet{
  border-color:#76c888;
  background-color:#76c888;  
}

.textcolor{
  color:white;
}


.v-btn {
    /* -webkit-box-align: center; */
    -ms-flex-align: center;
    /* align-items: center; */
    /* border-radius: 2px; */
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 36px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 500;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 6px 0px;
    min-width: 88px;
    outline: 0;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    /* vertical-align: middle; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.v-sheet {
    border-radius: 0px !important;
}
.fondoBarra{
  background-color: #2935a3;
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
    background-color: #293A5E !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.detaBarra{    
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 58px;
}

.entradasBtm{
    width:2vw;
    height:2vw;
  }

  .incidentBtmBtm{
    width:2vw;
    height:2vw;
  }

  .buquesBtm{
    width:2vw;
    height:2vw;  
  }

  .leaflet-layer {
    z-index:1 !important;
  }



/*//////////ESTILOS PORTRAIT ////////////////////////////////*/
@media only screen and (orientation: portrait) {
  .logoAmura{
    max-width:150px;
    margin-left:40px;
    
  }

  .entradasBtm{
    width:8vw;
    height:5vw;
  }

  .incidentBtmBtm{
    width:4vw;
    height:4vw;
  }

  .buquesBtm{
    width:4vw;
    height:4vw;  
  }


  .botonClosePopup {
    margin-top: -4vh;
    margin-left: 24.5vw;
    clear: both;
    position: absolute;
  }

  .bocadilloPU{
   /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #FF4B66;
    position: absolute;
    top: calc(500 - var(--pointsX)); ;
    left: var(--pointsY) ;
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
  }

}


.divider{
  border-color: #697EA8;
  margin-top: 0; 
  margin-bottom: 0;
  margin-right: 0 !important;
}

.logout{
  margin: auto;
  cursor: pointer;
}

</style>
<style lang="scss" scoped>
.v-card{
  background-color: #293A5E !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>

   
  